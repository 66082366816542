import React from "react";
import Menu from "../../../Menu/Menu";
import Footer1 from "../../../Footer-1/Footer";

const FunLearning = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Menu />
      <div
        style={{
          textAlign: "center",
        }}
      >
        <h1>FUN LEARNING VIA GAMES AND LIVE INTERACTION</h1>
        <p>
          You will enjoy studying and getting fun learning by interacting with
          your lessons and learning contents in several ways. Not only fun
          learning but also deeper understanding with less efforts. Now
          memorizing the terminology and its definition is made fun. Games are
          automatically generated by the system for you to enjoy memorizing the
          spelling of the words and remember their definitions.
        </p>

        <video width="600" height="450" controls>
          <source
            src="https://scube-applications-media54cbabfc-u3d19945rbtv.s3.eu-west-1.amazonaws.com/Interactive-Objects-m.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <Footer1 />
    </div>
  );
};

export default FunLearning;
